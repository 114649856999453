import React, { useRef } from "react";
import { Box, Button, Container, Flex, Image, Show, Text } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Routes } from "@blitzjs/next";

import GridBg from "../../assets/gridbackground.svg";
import preview from "../../assets/preview.png";
import { Heading } from "../Heading";
import { InternalLink } from "../../../core/components/Link/InternalLink";
import Link from "next/link";

interface Props {}

export const HeaderHeading = (props) => <Heading fontSize={{ md: 96, base: 38 }} {...props} />;

export const Header: React.FC<Props> = () => {
  const { t } = useTranslation("web");
  const section = useRef(null);

  useGSAP(
    () => {
      gsap.registerPlugin(ScrollTrigger);

      /* gsap.to(".gridBackground", {
        y: -50,
        scrollTrigger: {
          trigger: section.current,
          start: "top top",
          end: "bottom center",
          markers: true,
        },
      })*/
    },
    { scope: section },
  );

  return (
    <Flex
      as="header"
      minHeight="80vh"
      pt={32}
      flexDirection="column"
      alignItems="flex-end"
      position="relative"
      overflow="hidden"
      ref={section}
    >
      <Box
        width="100%"
        height="100%"
        position="absolute"
        left={0}
        top={20}
        zIndex={1}
        background="url('assets/images/gridbackground.svg') repeat center center"
        className={"gridBackground"}
      ></Box>

      <Container zIndex={10} position="relative" maxWidth="container.xl">
        <Flex gap={10} alignItems={{ base: "flex-end", lg: "center" }}>
          <HeaderHeading>{t("header_journalists")}</HeaderHeading>
          <Text maxW="340px">
            <Show below="md">{t("aprety_description_mobile")}</Show>
            <Show above="md">{t("aprety_description")}</Show>
          </Text>
        </Flex>
        <HeaderHeading>
          {t("with_stories")}&nbsp;
          <Text as="span" fontWeight="bold">
            {t("header_people")}
          </Text>
        </HeaderHeading>

        <Flex
          alignItems={{ base: "flex-end", lg: "flex-start" }}
          gap={20}
          flexDirection={{ base: "column", lg: "row" }}
        >
          <Flex alignItems="flex-end" flexDirection={{ base: "column", lg: "column" }} gap={10}>
            <Flex gap={10} alignItems={{ base: "center" }}>
              <Text maxW={{ base: "50%", lg: "340px" }}>
                <Show below="md">{t("are_you_ready_mobile")}</Show>
                <Show above="md">{t("are_you_ready")}</Show>
              </Text>
              <HeaderHeading fontWeight="bold">{t("together")}</HeaderHeading>
            </Flex>

            <Flex
              justifyContent={{ base: "flex-start", lg: "flex-end" }}
              alignItems={{ base: "flex-start" }}
              flexDirection={{ base: "column", lg: "row" }}
              gap={4}
              width="100%"
            >
              <InternalLink href={Routes.SignupPage()} target="_blank">
                <Button variant="secondary">{t("become_beta_tester")}</Button>
              </InternalLink>
              <Link href={t("become_investor_href")} target={"_blank"}>
                <Button variant="primary">{t("become_investor")}</Button>
              </Link>
            </Flex>
          </Flex>

          <Image
            src={preview.src}
            width={{ base: "50%", lg: "400px" }}
            mt={{ base: "-150px", lg: "0" }}
            alt=""
          />
        </Flex>
      </Container>
    </Flex>
  );
};
