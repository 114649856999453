import { BlitzPage } from "@blitzjs/next";
import { Box } from "@chakra-ui/react";
import Layout from "app/core/layouts/Layout";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { TextPlugin } from "gsap/TextPlugin";
import { Playfair_Display } from "next/font/google";

import { Navbar } from "../app/web/components/Navbar/Navbar";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { Header } from "../app/web/components/Header/Header";
import { WHITE_BG } from "../app/core/theme/foundations/colors";
import { Mission } from "../app/web/components/Mission/Mission";
import { People } from "../app/web/components/People/People";
import { ContactUs } from "../app/web/components/ContactUs/ContactUs";
import { Footer } from "../app/web/components/Footer/Footer";
import { navItems } from "../app/web/components/Navbar/data";
import { AppButton } from "../app/web/components/Navbar/AppButton";
import React from "react";

const Home: BlitzPage = () => {
  useGSAP(() => {
    gsap.registerPlugin(ScrollTrigger, TextPlugin);
  });

  return (
    <Box width="100%" height={"100%"} bgColor={WHITE_BG}>
      <Header />
      <Mission />
      <People />
      <ContactUs />

      <Footer />
    </Box>
  );
};

Home.suppressFirstRenderFlicker = true;
Home.getLayout = (page) => <Layout title="Home">{page}</Layout>;

export async function getStaticProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ["core", "web"])),
      // Will be passed to the page component as props
    },
  };
}

export default Home;
